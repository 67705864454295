body {
  background-color: #f0f0f0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.custom-shape-divider-bottom-1682179218 {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1682179218 svg {
  display: block;
  width: calc(116% + 1.3px);
  height: 98px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-fill {
  position: absolute;
  height: 10px;
  width: 100%;
  bottom: -1px;
  left: 0;
}

.custom-shape-divider-bottom-1685741602 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1685741602 svg {
  position: relative;
  display: block;
  width: calc(152% + 1.3px);
  height: 45px;
}

.custom-shape-divider-bottom-1685741602 .shape-fill {
  fill: #c5c5cc;
}
.scroll-container {
  overflow-y: auto;
  scroll-snap-type: y mandatory;
}
.scroll-area {
  scroll-snap-align: start;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#hover-card > #hidden-child {
  visibility: hidden;
}

#hover-card:hover > #hidden-child {
  visibility: visible;
}

.react-date-picker__inputGroup {
  padding: 2px;
}

.react-date-picker__wrapper {
  border-width: 0px;
  border-bottom-width: 1px;
}
.react-calendar {
  border-radius: 5px !important;
}

.react-calendar__tile--active {
  background: #ef7b11 !important;
}

.react-calendar__tile--active:hover {
  background: #e6e6e6 !important;
}
