@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'avenir';
  src: url('./fonts/AvenirNextLTPro-Regular.otf');
}

@font-face {
  font-family: 'avenir-bold';
  src: url('./fonts/AvenirNextLTPro-Bold.otf');
}
